export const invoiceStatusNames = {
  1: 'unpaid',
  2: 'partially_paid',
  3: 'paid',
  4: 'partially_credited',
  5: 'credited',
  6: 'lost',
  7: 'over_paid',
  11: 'paused',
  12: 'open',
  13: 'closed',
  14: 'inkasso',
  15: 'reminder',
}

export const invoiceStatus = {
  Unpaid: 1,
  Partially_paid: 2,
  Paid: 3,
  Partially_credited: 4,
  Credited: 5,
  End_with_loss: 6,
  Over_paid: 7,
  Paused: 11,
  Open: 12,
  Closed: 13,
  Inkasso: 14,
  Reminder: 15,
};

export const invoiceStatusColor = {
  1: 'chip lighten-5 red red-text',
  2: 'chip light-green lighten-5 green-text',
  3: 'chip lighten-5 green green-text',
  4: 'chip amber lighten-5 orange-text',
  5: 'chip lighten-5 orange orange-text',
  6: 'chip grey lighten-1 black-text',
  7: 'chip accent-1 green green-text',
  12: 'chip light-blue lighten-5 black-tex',
  13: 'chip lighten-5 green black-text',
  14: 'chip lighten-5 blue black-text',
  15: 'chip lighten-5 light-blue green-text',
};
