import React from 'react';
import { Link } from 'react-router-dom';
import Selection from './Selection';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.nextHandle = this.nextHandle.bind(this);
    this.goStartHandle = this.goStartHandle.bind(this);
    this.previousHandle = this.previousHandle.bind(this);
    this.goEndHandle = this.goEndHandle.bind(this);
  }

  handleChangeRowPerPage = evt => {
    const value = evt.target.value;
    return this.props.changePageSize(value);
  }

  from = () => {
    const { total } = this.props;
    return total === 0 ? 0 : (this.props.pageNumber - 1) * this.props.pageSize + 1;
  }

  to = () => {
    const { pageNumber, pageSize, total } = this.props;
    const toPage = pageNumber * pageSize;
    return toPage >= total ? total : toPage;
  }

  nextHandle = (e) => {
    const { total, pageNumber, nextHandle } = this.props;
    e.preventDefault();
    const toPage = this.to();
    if (toPage !== total) {
      return nextHandle(pageNumber + 1);
    }
    return false;
  }

  goStartHandle() {
    if (this.from() > 1) {
      return this.props.goStartHandle(1);
    }
    return false;
  }

  previousHandle(e) {
    e.preventDefault();
    if (this.from() > 1) {
      return this.props.previousHandle(this.props.pageNumber - 1);
    }
    return false;
  }

  goEndHandle(e) {
    e.preventDefault();
    const toPage = this.to();
    if (toPage !== this.props.total) {
      return this.props.goEndHandle(Math.ceil(this.props.total / this.props.pageSize));
    }
    return false;
  }

  renderRowPerPage = () => {
    const { total, pageSize } = this.props;
    let options = [];
    if (total >= 15) options = [...options, { text: 15, value: 15 }];
    if (total >= 15 || total >= 30) options = [...options, { text: 30, value: 30 }];
    if (total >= 30 || total >= 50) options = [...options, { text: 50, value: 50 }];
    if (total >= 50 || total >= 100) options = [...options, { text: 100, value: 100 }];
    return (
      <li className="d-inline-block" style={{ verticalAlign: 'sub' }}>
        <Selection options={options} handleChange={this.handleChangeRowPerPage} classes="h-auto" defaultValue={pageSize} />
      </li>
    );
  }

  render() {
    const { total, isShowPageSize } = this.props;
    const from = this.from();
    const to = this.to();
    return (
      <div className="d-flex justify-content-center align-items-center">
        <ul className="pagination d-inline center">
          <li>{`${from} - ${to} of ${total}`}</li>
          <li className={from <= 1 ? 'disabled' : 'waves-effect'}>
            <div onClick={this.goStartHandle}>
              <Link to="#" onClick={e => e.preventDefault()}>
                <i className="material-icons">skip_previous</i>
              </Link>
            </div>
          </li>
          <li className={from <= 1 ? 'disabled' : 'waves-effect'}>
            <div onClick={this.previousHandle}>
              <Link to="#" onClick={e => e.preventDefault()}>
                <i className="material-icons">chevron_left</i>
              </Link>
            </div>
          </li>
          <li
            className={to === total ? 'disabled' : 'waves-effect'}
          >
            <div onClick={this.nextHandle}>
              <Link to="#" onClick={e => e.preventDefault()}>
                <i className="material-icons">chevron_right</i>
              </Link>
            </div>
          </li>
          <li className={to === total ? 'disabled' : 'waves-effect'}>
            <div onClick={this.goEndHandle}>
              <Link to="#" onClick={e => e.preventDefault()}>
                <i className="material-icons">skip_next</i>
              </Link>
            </div>
          </li>
          {isShowPageSize === false ? null : total > 15 && this.renderRowPerPage()}
        </ul>
      </div>
    );
  }
}

export default Pagination;
