import * as types from '../actionTypes';

const resetFilter = {
  companies_id: 0,
  keyword: '',
  organization_number: '',
  customer_name: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  customerListData: {
    total: 0,
    rows: []
  },
  customerInfo: null,
  customerDataBrreg: null,
  newCustomer: null,
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case types.CUSTOMER.SET_FILTER_CUSTOMER:
      return {
        ...state,
        filter: action.payload
      };

    case types.CUSTOMER.RESET_FILTER_CUSTOMER:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    case types.CUSTOMER.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // LIST
    case types.CUSTOMER.FETCH_CUSTOMER_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CUSTOMER.FETCH_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        customerListData: action.payload
      };
    case types.CUSTOMER.FETCH_CUSTOMER_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };

    // LIST AUTOCOMPLETE
    case types.CUSTOMER.FETCH_CUSTOMER_AUTOCOMPLETE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CUSTOMER.FETCH_CUSTOMER_AUTOCOMPLETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        autocompleteData: action.payload
      };
    case types.CUSTOMER.FETCH_CUSTOMER_AUTOCOMPLETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };

    // ADD
    case types.CUSTOMER.ADD_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CUSTOMER.ADD_CUSTOMER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        newCustomer: action.payload
      };
    case types.CUSTOMER.ADD_CUSTOMER_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET-DETAIL
    case types.CUSTOMER.GET_CUSTOMER_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case types.CUSTOMER.GET_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        customerInfo: action.payload
      };
    case types.CUSTOMER.GET_CUSTOMER_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET-BRREG
    case types.CUSTOMER.GET_DATA_BRREG:
      return {
        ...state,
        loading: true,
      };
    case types.CUSTOMER.GET_DATA_BRREG_SUCCESS:
      return {
        ...state,
        loading: false,
        customerDataBrreg: action.payload
      };
    case types.CUSTOMER.GET_DATA_BRREG_FAIL:
      return {
        ...state,
        loading: false,
      };
    
    //DEFAULT
    default:
      return state;
  }
};
export default customer;
