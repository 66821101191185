import './AddCompany.css';

import { CompanyUrl, PrefixUrl } from '../../constants/routes.constants';
import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import actions, { fetchListCustomerAutocomplete, getOptions } from '../../store/actions';

import Autocomplete from 'react-autocomplete';
import Loading from '../UI/Loading';
import { connect } from 'react-redux';
import pages from '../../constants/pages.constants';
import { withNamespaces } from 'react-i18next';

class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueAutoComplete: '',
    };
  }

  componentDidMount = async () => {
    const { t, company, dispatch } = this.props;
    dispatch(actions.setBreadcrumb({
      title: t('breadcrumb'),
      page: pages.AddCompany,
    }));

    if (company && company.CompanyId) {
      await dispatch(getOptions({ companies_id: 0 }));
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.company !== this.props.company) {
      const { company, dispatch } = this.props;
      if (company && company.CompanyId) {
        await dispatch(getOptions({ companies_id: 0 }));
      }
    }
  }

  autoCompleteHandleChange = async (event, value) => {
    const { dispatch } = this.props;
    this.setState({ valueAutoComplete: event.target.value });
    await dispatch(fetchListCustomerAutocomplete(event.target.value));
  }

  onSelected = async (value, item) => {
    const { dispatch, history } = this.props;
    this.setState({ valueAutoComplete: value, customerLoaded: true });
    await dispatch(fetchListCustomerAutocomplete(item.value));
    history.push(CompanyUrl.NewCompanyOrganization.replace(':id', item.value));
  }

  render() {
    const { t, company, loading, autocompleteData } = this.props;
    const { valueAutoComplete } = this.state;

    let itemsData = [];
    if (autocompleteData) {
      for (const [key, value] of Object.entries(autocompleteData)) {
        itemsData.push({ label: `${value} (${key})`, value: key })
      }
    }

    return (
      <div id="search-page" className="row">
        <div className="col s12">
          <div className="container">
            <div id="search-wrapper" className="search section">
              <div className="row pad-tb">
                <div className="col s6 m8">
                  <Link
                    to={`${PrefixUrl}/`}
                    component={null}
                    className="btn modal-action white blue-text"
                  >
                    <i className="material-icons" style={{ verticalAlign: 'middle' }}>arrow_back</i>
                    {t('back')}
                  </Link>
                </div>
              </div>

              <Loading loading={loading} />
              <div className="col s12 m8 offset-m2 mt-4">
                <h5 style={{ textAlign: 'center' }}>{t('search_company')}</h5>
                <div className="input-field">
                  {itemsData && (
                    <Autocomplete
                      getItemValue={(item) => item.label}
                      items={itemsData}
                      renderItem={(item, isHighlighted) =>
                        parseInt(item.value) > 0 && (
                          <div style={{ background: isHighlighted ? 'lightgray' : '', padding: '5px' }}
                            data-key={item.value} key={item.value}>
                            {item.label}
                          </div>
                        )
                      }
                      value={valueAutoComplete}
                      onChange={this.autoCompleteHandleChange}
                      onSelect={this.onSelected}
                      wrapperStyle={{
                        display: 'block'
                      }}
                      menuStyle={{
                        borderRadius: '3px',
                        position: 'fixed',
                        left: 'auto',
                        top: 'auto',
                        overflow: 'auto',
                        maxHeight: '50%',
                        'zIndex': '1',
                        border: '1px solid rgb(222, 222, 222)',
                        background: '#ffffff'
                      }}
                      inputProps={{ placeholder: t('search_company') }}
                    />
                  )}
                </div>
                <p style={{ textAlign: 'center' }}>{t('search_company_description')} <Link to={CompanyUrl.NewCompany} component={null}> {t('search_company_description2')}</Link></p>
              </div>
            </div>

            <div className="content-overlay" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.customer.loading;
  const company = state.company.company;
  const autocompleteData = state.customer.autocompleteData;
  return {
    loading,
    company,
    autocompleteData,
  };
};
export default withRouter(connect(mapStateToProps)(withNamespaces('company')(AddCompany)));
