import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { CompanyUrl, RoutesUrl } from '../../constants/routes.constants';
import { getDataBrreg, getOptions } from '../../store/actions';

import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import Swal from 'sweetalert2';
import authenticationServices from '../../services/authentication.services';
import orytonServices from '../../services/oryton.services';
import Loading from '../UI/Loading';
import NewCompanyForm from './NewCompanyForm';

class NewCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueAutoComplete: '',
    };
  }

  componentDidMount = async () => {
    const { match: { params }, dispatch } = this.props;
    const { id } = params;
    if (id) {
      this.getData(id);
    }
    await dispatch(getOptions({ companies_id: 0 }));
  }

  componentDidUpdate = async (prevProps) => {
    const { match: { params }, dispatch } = this.props;
    const { id } = params;
    if (id && id !== prevProps.match.params.id) {
      this.getData(id);
      await dispatch(getOptions({ companies_id: 0 }));
    }
  }

  goBack = async (e) => {
    e.preventDefault();
    const { dispatch, history } = this.props;
    await dispatch(getDataBrreg(''));
    await dispatch(reset('formNewCompany'));
    history.push(CompanyUrl.AddCompany);
  }

  getData = async (id) => {
    const { dispatch } = this.props;
    if (id) {
      await dispatch(getDataBrreg(id));
    }
  }

  handleSubmitForm = async (values) => {
    const { t, history, countries } = this.props;
    const c = countries.find(c => c.alpha_code === values.PhoneCode);
    const params = {
      CompanyName: values.CompanyName,
      OrganisationNumber: values.OrganisationNumber,
      OrganisationForm: values.OrganisationForm,
      CountryCode: values.CountryCode,
      OfficeAddress1: values.OfficeAddress1,
      OfficeAddress2: values.OfficeAddress2,
      OfficeZipCode: values.OfficeZipCode,
      OfficeDistrict: values.OfficeDistrict,
      OfficeCity: values.OfficeCity,
      Website: values.Website,
      Email: values.Email,
      Phone: values.Phone,
      Address1: values.Address1,
      Address2: values.Address2,
      ZipCode: values.ZipCode,
      District: values.District,
      PhoneCode: c.phone_code,
      RegisteredFrom: 'invoice'
    };

    const response = await orytonServices.addCompany(params);
    if (response.isSuccess) {
      await Swal.fire({
        title: t('add_company'),
        html: t('add_company_success'),
        icon: 'success',
      });
      await history.push(RoutesUrl.Dashboard);
    } else {
      await Swal.fire({
        title: t('add_company'),
        html: response.message ? response.message : t('add_company_failed'),
        icon: 'error',
      });
    }
  }

  render() {
    const { t, loading, customerDataBrreg, countries } = this.props;
    const initialValues = buildInitialValues(customerDataBrreg, countries);

    return (
      <div className="row">
        <div className="col s12">
          <div className="container">
            <Loading loading={loading} />
            <div className="section">
              <div className="row pad-tb">
                <div className="col s6 m8">
                  <Link
                    to='#'
                    component={null}
                    className="btn modal-action white blue-text"
                    onClick={e => this.goBack(e)}
                  >
                    <i className="material-icons" style={{ verticalAlign: 'middle' }}>arrow_back</i>
                    {t('back')}
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col s12">
                  <div className="card">
                    <div className="card-content">
                      <NewCompanyForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmitForm}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-overlay" />
          </div>
        </div>
      </div>
    );
  }
}

const buildInitialValues = (customerDataBrreg, countries) => {
  const user = authenticationServices.getUserLogin();
  let {
    CompanyName,
    OrganisationNumber,
    OrganisationForm,
    OfficeAddress1,
    OfficeAddress2,
    OfficeZipCode,
    OfficeDistrict,
    OfficeCity,
    Address1,
    Address2,
    District,
    ZipCode,
    City,
    Website,
    disabled,
    Email,
    Phone,
    PhoneCode,
    CountryCode,
  } = '';
  disabled = CompanyName || OrganisationNumber ? true : false;
  if (customerDataBrreg) {
    const {
      organisasjonsnummer,
      navn,
      organisasjonsform,
      forretningsadresse,
      postadresse,
      hjemmeside
    } = customerDataBrreg;
    OrganisationNumber = organisasjonsnummer;
    CompanyName = navn;
    if (organisasjonsform && organisasjonsform.kode) {
      OrganisationForm = organisasjonsform.kode;
    }
    if (forretningsadresse) {
      OfficeAddress1 = forretningsadresse.adresse[0];
      OfficeAddress2 = forretningsadresse.adresse[1];
      OfficeZipCode = forretningsadresse.postnummer;
      OfficeDistrict = forretningsadresse.poststed;
      OfficeCity = '';
      CountryCode = countries?.find(c => c.alpha_code === forretningsadresse.landkode)?.alpha_code ?? '';
    }
    if (postadresse) {
      Address1 = postadresse.adresse[0];
      Address2 = postadresse.adresse[1];
      ZipCode = postadresse.postnummer;
      District = postadresse.poststed;
    }
    if (hjemmeside) {
      Website = hjemmeside;
    }
  }
  if (user && user.CountryMobile) {
    const c = countries?.find(c => c?.phone_code === user?.CountryMobile)
    PhoneCode = c?.alpha_code ?? 'NO';
  }
  if (user && user.Email) {
    Email = user.Email;
  }
  if (user && user.Phone) {
    Phone = user.Phone;
  }
  return {
    disabled,
    CompanyName,
    OrganisationNumber,
    OrganisationForm,
    OfficeAddress1,
    OfficeAddress2,
    OfficeZipCode,
    OfficeDistrict,
    OfficeCity,
    Address1,
    Address2,
    District,
    ZipCode,
    City,
    Website,
    Email,
    Phone,
    PhoneCode: PhoneCode ? PhoneCode : 'NO',
    CountryCode,
  };
}

const mapStateToProps = state => {
  const loading = state.customer.loading;
  const company = state.company.company;
  const countries = state.options.countries;
  const customerDataBrreg = state.customer.customerDataBrreg;
  return {
    loading,
    company,
    countries,
    customerDataBrreg,
  };
};
export default withRouter(connect(mapStateToProps)(withNamespaces('company')(NewCompany)));
