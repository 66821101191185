import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import currencyHelper from '../../../../helpers/currencyHelper';
import ModalDraggable from '../../../UI/modal-draggable/ModalDraggable';
import RegisterPaymentForm from '../form/RegisterPaymentForm';

class RegisterPaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleSubmitForm = async (values) => {

  }

  render() {
    const { openModal, onClose, t, invoiceDetail, getData, bankAccounts } = this.props;

    const invoiceAmount = invoiceDetail ? currencyHelper.processAmount(invoiceDetail) : {};
    let {
      amount,
      outstanding
    } = invoiceAmount;

    amount = outstanding ? currencyHelper.formatMoney(outstanding) : 0;
    const payment_date = new Date();
    const fee = currencyHelper.formatMoney(0);

    let bank_account = '';
    let bank_account_name = '';
    if (Number(invoiceDetail?.bank_account_id) > 0) {
      const bankInfo = bankAccounts.find((b) => b.Id === Number(invoiceDetail.bank_account_id));
      if (bankInfo) {
        bank_account = bankInfo.AccountNumber;
        bank_account_name = bankInfo.AccountName;
      }
    }

    const initialValues = { payment_method: invoiceDetail?.bank_account_id ? invoiceDetail.bank_account_id : '', payment_date, amount, fee, outstanding, bank_account, bank_account_name };

    return (
      <ModalDraggable
        title={`${t('new_payment_from')} ${invoiceDetail?.customer_name}`}
        open={openModal}
        onOk={onClose}
        onCancel={onClose}
        width={'550px'}
        bodyStyle={{ paddingTop: '10px' }}
        content={
          <div className="modal-content">
            <RegisterPaymentForm
              onSubmit={this.handleSubmitForm}
              initialValues={initialValues}
              onClose={onClose}
              invoiceDetail={invoiceDetail}
              getData={getData}
            />
          </div>
        }
      />
    );
  }

}

const mapStateToProps = state => {
  const bankAccounts = state.bankAccounts.bankAccounts;
  return {
    bankAccounts
  };
};

export default withRouter(connect(mapStateToProps)(withNamespaces('invoices')(RegisterPaymentModal)));
