import './SendEmailForm.css';

import * as Utils from '../../../../utils/Utils';

import { Field, Form, getFormValues, reduxForm, reset, submit } from 'redux-form';
import { renderField, renderHiddenInputField } from '../../../UI/reduxForm';

import moment from 'moment-timezone';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { invoiceTypes } from '../../../../constants/invoice-types.constants';
import currencyHelper from '../../../../helpers/currencyHelper';
import timeHelper from '../../../../helpers/timeHelper';
import registerPaymentServices from '../../../../services/registerPayment.services';
import store from '../../../../store';
import { getCounterRequest } from '../../../../store/actions';
import renderDatePicker from '../../../UI/input-date-picker';
import Loading from '../../../UI/Loading';
import Select from '../../../UI/reactSelect/ReactSelect';

class RegisterPaymentForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
      loading: false,
      paymentMethod: '',
      paymentDate: new Date(),
      amount: 0,
      fee: 0,
    };
  }

  buildOptions = () => {
    const { t, bankAccounts } = this.props;
    const optionsPaymentMethod = [];
    if (bankAccounts) {
      bankAccounts.map((row, index) => {
        optionsPaymentMethod.push({
          value: row.Id,
          label: Utils.bankNumber(row.AccountNumber) + ' ' + row.AccountName
        });
      })
      optionsPaymentMethod.push({
        value: 'cash',
        label: t('cash')
      });
      optionsPaymentMethod.push({
        value: 'other',
        label: t('other')
      })
    }

    return {
      optionsPaymentMethod,
    }
  }


  handleSelect = (field, selected) => obj => {
    const { dispatch, change, bankAccounts } = this.props;
    const { value, label } = obj;
    this.setState({
      [selected]: value
    }, () => dispatch(change(field, value)));

    if (field === 'payment_method') {
      if (value !== 'cash' && value !== 'other') {
        const bankInfo = bankAccounts.find((b) => b.Id === Number(value));
        if (bankInfo) {
          dispatch(change('bank_account', bankInfo.AccountNumber));
          dispatch(change('bank_account_name', bankInfo.AccountName));
        }
      }
    }
  }

  handleChangePhone = value => {
    const { dispatch, change } = this.props;
    this.setState({ phone: value }, () => dispatch(change('phone_number', value)));
  }

  changeThisDate = value => (event) => {
    event.preventDefault();
    const { change } = this.props;
    const newDate = new Date(value);
    this.setState({ paymentDate: newDate }, () => change('payment_date', moment(newDate).format('DD.MM.YYYY')));
  }

  onBlurPrice = async (event) => {
    const { dispatch, change } = this.props;
    let value = event.target.value;
    let name = event.target.name;
    value = value.replace(/[a-zA-Z]/g, '');
    name = name.replace('[', '.').replace(']', '');
    this.setState({
      [name]: currencyHelper.formatMoney(value)
    }, () => dispatch(change(name, currencyHelper.formatMoney(value))));
  }

  required = value => {
    const { t } = this.props;
    console.log('value', value);
    return value ? undefined : t('validation.required');
  }

  validMoney = value => {
    const { t } = this.props;
    return isNaN(value[value.length - 1]) ? t('validation.invalidAmount') : undefined;
  }

  minValue = value => {
    const { t } = this.props;
    return value && parseFloat(value) < 1 ? t('validation.minValue').replace('{min}', '1') : undefined;
  }

  convertFloat(val) {
    let newVal = parseFloat(val);
    if (typeof val === 'string') {
      newVal = parseFloat(val.replace(/\s/g, '').replace(',', '.'));
    }
    if (!isNaN(newVal))
      return newVal;
    return 0;
  }

  saveRegisterPayment = async () => {
    this.setState({ loading: true });
    const { dispatch, formValues, invoiceDetail, onClose, t, getData } = this.props;
    const state = store.getState();
    const { company } = state.company;
    await dispatch(submit('registerPaymentForm'));
    if (this.props.valid) {
      const data = {
        invoice_id: invoiceDetail.id,
        companies_id: company.CompanyId,
        payment_method: formValues.payment_method,
        amount: formValues.amount,
        fee: formValues.fee ?? 0,
        bank_account: formValues.bank_account,
        bank_account_name: formValues.bank_account_name,
      };
      if (formValues.payment_date) {
        data.date = timeHelper.formatDB(formValues.payment_date, 'DD.MM.YYYY');
      }

      const response = await registerPaymentServices.save(data);
      if (response.success) {
        if (onClose) await onClose();
        await Swal.fire({
          title: t('register_payment'),
          html: t('register_payment_success'),
          icon: 'success',
        })
        if (getData) await getData();
        await dispatch(getCounterRequest({ companies_id: company.CompanyId, currency_id: 1 }));
        await dispatch(reset('registerPaymentForm'));
      } else {
        await Swal.fire({
          title: t('register_payment'),
          html: t(response?.message) ?? t('register_payment_failed'),
          icon: 'error',
        });
      }
    }
    this.setState({ loading: false });
  }

  renderSendInvoice = () => {
    const { t, invoiceDetail, initialValues } = this.props;
    const { paymentMethod, paymentDate } = this.state;
    const { optionsPaymentMethod } = this.buildOptions()

    let invoiceNoLabel = t('invoice_number');
    let invoice_no = invoiceDetail?.invoice_no;
    if (invoiceDetail?.type === invoiceTypes.OrderInvoice) {
      invoiceNoLabel = t('order_number');
      invoice_no = invoiceDetail?.order_no;
    }
    if (invoiceDetail?.type === invoiceTypes.OfferInvoice) {
      invoiceNoLabel = t('offer_number');
      invoice_no = invoiceDetail?.offer_no;
    }
    if (invoiceDetail?.type === invoiceTypes.CreditNoteInvoice) {
      invoiceNoLabel = t('credit_note_number');
    }

    const paymentMethodSelected = paymentMethod ? paymentMethod : (initialValues?.payment_method ? initialValues.payment_method : 0);
    const amount = this.state.amount ? this.state.amount : (initialValues?.amount ? initialValues.amount : 0);
    const fee = this.state.fee ? this.state.fee : (initialValues?.fee ? initialValues.fee : 0);
    const rest = initialValues.outstanding && this.state.amount ? this.convertFloat(initialValues.outstanding) - this.convertFloat(amount) : (initialValues.outstanding ? initialValues.outstanding : 0);
    return (
      <>
        <div className="row">
          <div className="col s12 pb-2 invoices-company-info">
            <dt>{t('sale')}</dt>
            <dd>
              {invoiceNoLabel} {invoice_no}, {t('amount')} {invoiceDetail?.amount ? currencyHelper.formatMoney(invoiceDetail?.amount) : 0} {t('date')} {invoiceDetail?.invoice_date ? timeHelper.formatDate(invoiceDetail?.invoice_date) : ''}
            </dd>
            <dt>{t('invoice_balance')}</dt>
            <dd>{initialValues?.amount ? currencyHelper.formatMoney(initialValues?.amount) : 0}</dd>
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            <div className="input-field">
              <label className="active" htmlFor="countries_id">{t('payment_method')}</label>
              <div className="react-select">
                <Select
                  selectedLabel={paymentMethodSelected}
                  groupOptions={optionsPaymentMethod}
                  handleSelect={this.handleSelect('payment_method', 'paymentMethod')}
                />
                <Field component={renderHiddenInputField} name="payment_method" validate={this.required} />
                <Field component={renderHiddenInputField} name="bank_account" />
                <Field component={renderHiddenInputField} name="bank_account_name" />
              </div>
            </div>
          </div>

          <div className="col col-invoice-date">
            <div className="input-field">
              <label className="active" htmlFor="payment_date">{t('payment_date')}</label>
              <Field
                name="payment_date"
                selected={paymentDate}
                inputValueFormat="DD.MM.YYYY"
                dateFormat="dd.MM.yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                normalize={value => (value ? moment(value).format('DD.MM.YYYY') : null)}
                component={renderDatePicker}
                validate={this.required}
              />
            </div>
          </div>
          <div className="col s12 width-price">
            <Field
              id="amount"
              name="amount"
              type="text"
              component={renderField}
              placeholder={t('amount')}
              onBlur={event => this.onBlurPrice(event)}
              validate={[this.required, this.validMoney]}
              className="right-align"
              label={t('amount')}
              value={amount}
            />
          </div>
          {/* <div className="col s12 width-price">
            <Field
              id="fee"
              name="fee"
              type="text"
              component={renderField}
              placeholder={t('fee_interest')}
              onBlur={event => this.onBlurPrice(event)}
              validate={[this.required, this.validMoney]}
              className="right-align"
              label={t('fee_interest')}
              value={fee}
            />
          </div> */}
          <div className="col s12">
            <p>{currencyHelper.formatMoney(amount)} {t('paid_from_customer')}, {currencyHelper.formatMoney(rest)} {t('rest_amount')}</p>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { handleSubmit, t, onClose } = this.props;
    const { loading } = this.state;
    return (
      <Form
        onSubmit={handleSubmit}
      >
        <Loading loading={loading} />

        {this.renderSendInvoice()}

        <div className="row" style={{ marginTop: '10px' }}>
          <div className="col s12 right-align">
            <button
              type="button"
              className="btn modal-action white blue-text"
              onClick={onClose}
            >
              {t('cancel')}
            </button>
            <button
              type="button"
              className="btn modal-action ml-1"
              onClick={this.saveRegisterPayment}
              disabled={loading}
            >
              {t('register_payment')}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const FormRegisterPayment = reduxForm({
  form: 'registerPaymentForm',
  enableReinitialize: true,
})(RegisterPaymentForm);

const mapStateToProps = state => {
  const loading = state.invoices.loading;
  const bankAccounts = state.bankAccounts.bankAccounts;
  const formValues = getFormValues('registerPaymentForm')(state);
  return {
    loading,
    bankAccounts,
    formValues
  };
};

export default connect(mapStateToProps)(withNamespaces('invoices')(FormRegisterPayment));
