import React, { Component } from 'react';
import actions, { getCustomerOverviewInvoices, getCustomerOverviewInvoicesSuccess, resetFilterInvoices, setFilterCustomerOverviewInvoices } from '../../store/actions';
import './CustomerOverviewList.css';

import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { invoiceStatus } from '../../constants/invoice-status.constants';
import pages from '../../constants/pages.constants';
import { ErrorRoutes } from '../../constants/routes.constants';
import customerOverviewServices from '../../services/customer-overview.services';
import { getCustomerOverviewInvoicesFail } from './../../store/actions/customer-overview.actions';
import CustomerOverviewListTable from './CustomerOverviewListTable';
import KeywordFilter from './components/KeywordFilter';
import StatusFilter from './components/StatusFilter';

class CustomerOverviewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalSendInvoice: false,
      openModalAttachFiles: false,
      showCreditNote: false,
      status: invoiceStatus.Unpaid,
      sales_man_id: ''
    };
  }

  componentDidMount = async () => {
    const { t, dispatch, company, match } = this.props;
    dispatch(actions.setBreadcrumb({
      title: t('breadcrumb'),
      page: pages.CustomerOverviewList
    }));

    return this.fetchData();
  }

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    const { dispatch, company } = this.props;
    if (prevProps.company !== company) {
      return this.fetchData();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetFilterInvoices());
  }

  fetchData = async () => {
    const { filter, dispatch, history, match } = this.props;

    const token_access = match.params.token_access;

    if (token_access) {
      let params = Object.assign({}, filter);
      params.token_access = token_access;
      params.status = this.state.status;

      const response = await customerOverviewServices.list(params);
      if (response.success) {
        await dispatch(setFilterCustomerOverviewInvoices(params));
        dispatch(getCustomerOverviewInvoicesSuccess(response?.data));
      } else {
        dispatch(getCustomerOverviewInvoicesFail(response?.data));
        return history.push(ErrorRoutes.BadRequest);
      }
    }
  }

  changeSearch = async (event) => {
    const { filter, dispatch, match } = this.props;

    const token_access = match.params.token_access;

    if (token_access) {
      let params = Object.assign({}, filter);
      params.token_access = token_access;
      params.status = this.state.status;
      params[event.target.name] = event.target.value;
      params.pageNumber = 1;
      params.pageSize = 15;

      await dispatch(setFilterCustomerOverviewInvoices(params));
      await dispatch(getCustomerOverviewInvoices(params));
    }
  }

  changeStatus = (name) => async evt => {
    const { filter, dispatch, match } = this.props;

    const token_access = match.params.token_access;

    if (token_access) {
      let params = Object.assign({}, filter);
      params.token_access = token_access;
      params[name] = evt.value;
      params.pageNumber = 1;
      params.pageSize = 15;

      params.type = 'invoice';
      this.setState({ [name]: evt.value });
      await dispatch(setFilterCustomerOverviewInvoices(params));
      await dispatch(getCustomerOverviewInvoices(params));
    }
  }

  rebuildRouter = router => {
    const { match } = this.props;
    if (match.params.company !== 'company') {
      return router.replace(':company', match.params.company);
    }
    return router;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="row">
        <div className="col s12">
          <div className="container">
            <div className="section">
              {/*stats list*/}
              <div className="row">
                <div className="col s12">
                  <div className="card">
                    <div className="card-title pad-10">
                      <div className="row">
                        <div className="col s12">
                          {t('customer_overview')}
                        </div>
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="row mb-1">
                        <KeywordFilter handleSearch={this.changeSearch} />
                        <StatusFilter status={this.state.status} changeStatus={this.changeStatus} />
                      </div>
                      <CustomerOverviewListTable fetchData={this.fetchData} />
                    </div>
                  </div>
                </div>
              </div>

              {/*end list*/}
            </div>
            <div className="content-overlay" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const filter = state.customerOverview.filter;
  const company = state.company.company;

  return {
    filter,
    company,
  };
};
export default withRouter(connect(mapStateToProps)(withNamespaces('invoices')(CustomerOverviewList)));
