import React, { Component } from 'react';

class Selection extends Component {
  renderItem(item, index, defaultValue) {
    return <option key={index} value={item.value} defaultValue={defaultValue}>{item.text}</option>;
  }

  render() {
    const {
      options,
      handleChange,
      classes,
      defaultValue
    } = this.props;
    
    return (
      <select
        onChange={handleChange}
        defaultValue={defaultValue}
        className={`browser-default ${classes}`}
      >
        {/* <option disabled>Choose your option</option> */}
        {options.map((item, index) => this.renderItem(item, index, defaultValue))}
      </select>
    );
  }
}
export default Selection;
