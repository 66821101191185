import * as types from '../actionTypes';

const resetFilterInvoiceReport = {
  keyword: '',
  date_option: 2,
  date_from: '',
  date_to: '',
  status: 1,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterProductSales = {
  keyword: '',
  date_option: 2,
  date_from: '',
  date_to: '',
  sortBy: 'invoice_date',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterCustomerInvoice = {
  keyword: '',
  date_option: 2,
  date_from: '',
  date_to: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterDiscountReport = {
  keyword: '',
  sortBy: 'customer_id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterAccountReceivableReport = {
  keyword: '',
  status: 1,
  date_option: 1,
  sortBy: 'created_date',
  sortType: 'asc',
  pageNumber: 1,
  pageSize: 15
};


const filterInvoiceReport = { ...resetFilterInvoiceReport };
const filterProductSales = { ...resetFilterProductSales };
const filterCustomerInvoice = { ...resetFilterCustomerInvoice };
const filterDiscountReport = { ...resetFilterDiscountReport };
const filterAccountReceivableReport = { ...resetFilterAccountReceivableReport };

const initialState = {
  loading: false,
  filterInvoiceReport,
  filterProductSales,
  filterCustomerInvoice,
  filterDiscountReport,
  filterAccountReceivableReport,
  invoiceReport: {
    total: 0,
    rows: []
  },
  productSales: {
    total: 0,
    rows: []
  },
  customerInvoice: {
    total: 0,
    rows: []
  },
  discountReport: {
    total: 0,
    rows: []
  },
  accountReceivableReport: {
    total: 0,
    rows: []
  },
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case types.REPORT.SET_FILTER_INVOICE_REPORT:
      return {
        ...state,
        filterInvoiceReport: action.payload
      };
    case types.REPORT.RESET_FILTER_INVOICE_REPORT:
      return {
        ...state,
        filterInvoiceReport: { ...resetFilterInvoiceReport }
      };

    case types.REPORT.SET_FILTER_PRODUCT_SALES:
      return {
        ...state,
        filterProductSales: action.payload
      };
    case types.REPORT.RESET_FILTER_PRODUCT_SALES:
      return {
        ...state,
        filterProductSales: { ...resetFilterProductSales }
      };

    case types.REPORT.SET_FILTER_CUSTOMER_INVOICE:
      return {
        ...state,
        filterCustomerInvoice: action.payload
      };
    case types.REPORT.RESET_FILTER_CUSTOMER_INVOICE:
      return {
        ...state,
        filterCustomerInvoice: { ...resetFilterCustomerInvoice }
      };

    case types.REPORT.SET_FILTER_DISCOUNT_REPORT:
      return {
        ...state,
        filterDiscountReport: action.payload
      };
    case types.REPORT.RESET_FILTER_DISCOUNT_REPORT:
      return {
        ...state,
        filterDiscountReport: { ...resetFilterDiscountReport }
      };

    case types.REPORT.SET_FILTER_ACCOUNT_RECEIVABLE_REPORT:
      return {
        ...state,
        filterAccountReceivableReport: action.payload
      };
    case types.REPORT.RESET_FILTER_ACCOUNT_RECEIVABLE_REPORT:
      return {
        ...state,
        filterAccountReceivableReport: { ...resetFilterAccountReceivableReport }
      };

    // GET INVOICE REPORT
    case types.REPORT.GET_INVOICE_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.REPORT.GET_INVOICE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceReport: action.payload
      };
    case types.REPORT.GET_INVOICE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET INVOICE REPORT
    case types.REPORT.GET_PRODUCT_SALES_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.REPORT.GET_PRODUCT_SALES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        productSales: action.payload
      };
    case types.REPORT.GET_PRODUCT_SALES_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET CUSTOMER INVOICE
    case types.REPORT.GET_CUSTOMER_INVOICE_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.REPORT.GET_CUSTOMER_INVOICE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        customerInvoice: action.payload
      };
    case types.REPORT.GET_CUSTOMER_INVOICE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET DISCOUNT REPORT
    case types.REPORT.GET_DISCOUNT_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.REPORT.GET_DISCOUNT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        discountReport: action.payload
      };
    case types.REPORT.GET_DISCOUNT_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET ACCOUNT RECEIVABLE REPORT
    case types.REPORT.GET_ACCOUNT_RECEIVABLE_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.REPORT.GET_ACCOUNT_RECEIVABLE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        accountReceivableReport: action.payload
      };
    case types.REPORT.GET_ACCOUNT_RECEIVABLE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default report;
