import * as types from '../actionTypes';

const resetFilter = {
  keyword: '',
  type: '',
  status: '',
  companies_id: 0,
  sales_man_id: '',
  sortBy: '',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  invoicesListData: {
    total: 0,
    rows: []
  },
  invoiceDetail: null,
  invoicePreview: null,
  showSendEmail: false,
  invoiceOptions: []
};

const invoices = (state = initialState, action) => {
  switch (action.type) {
    case types.INVOICES.SET_FILTER_INVOICES:
      return {
        ...state,
        filter: action.payload
      };
    case types.INVOICES.RESET_FILTER_INVOICES:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    case types.INVOICES.SHOW_SEND_EMAIL:
      return {
        ...state,
        showSendEmail: true
      };

    case types.INVOICES.SHOW_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        showSendEmail: false
      };

    case types.INVOICES.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // LIST
    case types.INVOICES.FETCH_INVOICES_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICES.FETCH_INVOICES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        invoicesListData: action.payload
      };
    case types.INVOICES.FETCH_INVOICES_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    // GET-DETAIL
    case types.INVOICES.GET_INVOICE_DETAIL:
      return {
        ...state,
        loading: true,
        invoiceDetail: null
      };
    case types.INVOICES.GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDetail: action.payload
      };
    case types.INVOICES.GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        loading: false
      };
    // ADD
    case types.INVOICES.ADD_INVOICES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICES.ADD_INVOICES_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.INVOICES.ADD_INVOICES_REQUEST_FAIL:
      return {
        ...state,
        loading: false
      };
    // UPDATE
    case types.INVOICES.UPDATE_INVOICES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICES.UPDATE_INVOICES_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.INVOICES.UPDATE_INVOICES_REQUEST_FAIL:
      return {
        ...state,
        loading: false
      };
    // UPDATE
    case types.INVOICES.DELETE_INVOICES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICES.DELETE_INVOICES_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.INVOICES.DELETE_INVOICES_REQUEST_FAIL:
      return {
        ...state,
        loading: false
      };
    // PREVIEW
    case types.INVOICES.INVOICE_PREVIEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICES.INVOICE_PREVIEW_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        invoicePreview: action.payload
      };
    case types.INVOICES.INVOICE_PREVIEW_REQUEST_FAIL:
      return {
        ...state,
        loading: false
      };
    // CREATE
    case types.INVOICES.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICES.CREATE_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.INVOICES.CREATE_INVOICE_REQUEST_FAIL:
      return {
        ...state,
        loading: false
      };
    // SEND INVOICE
    case types.INVOICES.SEND_INVOICE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICES.SEND_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.INVOICES.SEND_INVOICE_REQUEST_FAIL:
      return {
        ...state,
        loading: false
      };
    // INVOICE OPTIONS
    case types.INVOICES.GET_INVOICE_OPTIONS:
      return {
        ...state,
        loading: true,
      };
    case types.INVOICES.GET_INVOICE_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceOptions: action.payload
      };
    case types.INVOICES.GET_INVOICE_OPTIONS_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoices;
