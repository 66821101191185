import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  list: async params => {
    try {
      const { token_access, ...query } = params;
      const queryParams = httpHelper.serialize(query);
      const url = `${api.customerOverview.list.replace(':token_access', token_access)}?${queryParams}`;
      const data = await BaseServices.getData({ url });
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
