import CrytoJs from 'crypto-js';
import moment from 'moment-timezone';
import react from 'react';
import serverConfigs from '../configs/server.configs';
import currencyHelper from '../helpers/currencyHelper';
import timeHelper from '../helpers/timeHelper';

export const setLocalStorage = (name, value) => {
  window.localStorage.setItem(name, value);
};

export const getLocalStorage = (name) => {
  return window.localStorage.getItem(name);
};

export const deleteLocalStorage = (name) => {
  window.localStorage.removeItem(name);
};

export const loadNewBody = (newClass) => {
  if (newClass.length > 0 && Array.isArray(newClass)) {
    document.body.classList.remove('2-columns');
    newClass.forEach((className) => {
      document.body.classList.add(className);
    });
  }
};

export const revertBody = (prevClass) => {
  if (prevClass.length > 0 && Array.isArray(prevClass)) {
    document.body.classList.add('2-columns');
    prevClass.forEach((className) => {
      document.body.classList.remove(className);
    });
  }
};

export const getAccessToken = () => {
  if (localStorage.getItem('_user')) {
    const _user = JSON.parse(localStorage.getItem('_user'));
    const { AccessToken } = _user;
    return AccessToken;
  }
  return null;
};

export const renderHTML = (rawHTML, tag = 'div') => react.createElement(tag, { dangerouslySetInnerHTML: { __html: rawHTML } });

export const bankNumber = (value) => {
  const onlyNums = value ? value.replace(/[^\d]/g, '') : '';
  if (onlyNums.length <= 4) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 4)}.${onlyNums.slice(4)}`;
  }
  return `${onlyNums.slice(0, 4)}.${onlyNums.slice(4, 6)}.${onlyNums.slice(6, 11)}`;
};

export const capitalize = str => {
  // capitalize first letter
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const capitalizeSpace = str => {
  // MySite = My Site
  return str.replace(/([A-Z])/g, ' $1').trim();
};

// get query string to object
export const getJsonFromUrl = (url) => {
  if (!url) url = window.location.search;
  const query = url.substr(1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const formatString = string => {
  let str = string
    .toString()
    .trim()
    .toLowerCase();
  const from = 'åãàáäâẽèéëêìíïîõòóöôùúüûñçø·/_,:;';
  const to = 'aaaaaaeeeeeiiiiooooouuuunco------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  return str;
};

export const slugify = string => {
  let str = string
    .toString()
    .trim()
    .toLowerCase();
  const from = 'åãàáäâẽèéëêìíïîõòóöôùúüûñçø·/_,:;';
  const to = 'aaaaaaeeeeeiiiiooooouuuunco------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

export const getUserLogin = () => {
  if (localStorage.getItem('_user')) {
    const _user = JSON.parse(localStorage.getItem('_user'));
    const userRoleToken = serverConfigs[process.env.NODE_ENV].TokenSecret;
    const bytes = CrytoJs.AES.decrypt(_user.RoleId, userRoleToken);
    if (bytes.sigBytes === 0) return null;
    const decode = JSON.parse(bytes.toString(CrytoJs.enc.Utf8));
    _user.RoleId = decode;
    return _user;
  }
  return null;
};

export const getUserRole = roleId => {
  switch (roleId) {
    case 2:
      return 'Admin';
    case 3:
      return 'Accountant';
    case 4:
      return 'Accountant Limit';
    case 5:
      return 'VN Accountant';
    case 6:
      return 'Recruitment';
    case 7:
      return 'Viet Nam Admin';
    case 14:
      return 'CEO';
    case 15:
      return 'HR Eco Finans AS';
    case 16:
      return 'HR Oryton';
    case 17:
      return 'Developer';
    case 18:
      return 'Admin Recruitment';
    default:
      return null;
  }
};

// capitalize first letter of each word
export const capitalizeLetter = (str) => {
  if (!str) return '';
  const result = str
    .split(' ')
    .map(val => {
      return val.search(/^(as|da|ans)/gi) === -1
        ? val.charAt(0).toUpperCase() + val.substr(1).toLowerCase()
        : val;
    })
    .join(' ');
  return result;
};

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const checkCompanyExist = (companyList, company) => {
  let isExist = false;
  if (companyList.length) {
    isExist = companyList.some(x => {
      return x.Alias === company;
    });
  }
  return isExist;
};

export const getCurrentCompany = props => {
  // required withRouter;
  const { match } = props;
  return match.params.company;
};

export const SSNCheck = (n) => {

  const Gender = { MALE: "MALE", FEMALE: "FEMALE" };

  const isValid = (n) => {
    if ("string" != typeof n) return !1;
    if (11 !== n.length) return !1;
    if (!/^\d+$/.test(n)) return !1;
    var r = n.substring(0, 6);
    return (
      isDNumber(n) &&
      (r =
        (parseInt(r.substring(0, 1), 10) - 4).toString() +
        r.substring(1, 6)),
      !!isDateValid(r) &&
      calculateFirstChecksum(n.substring(0, 9)).toString() === n[9] &&
      calculateSecondChecksum(n.substring(0, 10)).toString() === n[10]
    );
  };

  const isDateValid = (n) => {
    var t = parseInt(n.substring(0, 2)),
      r = parseInt(n.substring(2, 4)),
      e = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return !(t < 1 || r < 1) && !(t > e[r - 1]);
  };

  const isDNumber = (n) => {
    var t = parseInt(n.substring(0, 2));
    return t > 40 && t < 72;
  }

  const getGender = (n) => {
    return (
      !!isValid(n) &&
      (n.substring(8, 9) % 2 == 0
        ? Gender.FEMALE
        : Gender.MALE)
    );
  }

  const calculateFirstChecksum = (n) => {
    if ("string" != typeof n) return !1;
    if (9 !== n.length) return !1;
    var t =
      11 -
      ((3 *
        (n = n.split("").map(function (n) {
          return parseInt(n);
        }))[0] +
        7 * n[1] +
        6 * n[2] +
        1 * n[3] +
        8 * n[4] +
        9 * n[5] +
        4 * n[6] +
        5 * n[7] +
        2 * n[8]) %
        11);
    return 10 !== t && (11 === t && (t = 0), t);
  }

  const calculateSecondChecksum = (n) => {
    if ("string" != typeof n) return !1;
    if (10 !== n.length) return !1;
    var t =
      11 -
      ((5 *
        (n = n.split("").map(function (n) {
          return parseInt(n);
        }))[0] +
        4 * n[1] +
        3 * n[2] +
        2 * n[3] +
        7 * n[4] +
        6 * n[5] +
        5 * n[6] +
        4 * n[7] +
        3 * n[8] +
        2 * n[9]) %
        11);
    return 10 !== t && (11 === t && (t = 0), t);
  }

  const getCentury = (n, t) => {
    var r;
    return (
      n >= 0 && n <= 499
        ? (r = 19)
        : n >= 500 && n <= 749 && t >= 55
          ? (r = 18)
          : n >= 500 && n <= 999 && t <= 39
            ? (r = 20)
            : n >= 900 && n <= 999 && t >= 40 && (r = 19),
      r
    );
  }

  const getBirthdate = (n) => {
    if (isValid(n)) {
      var r = parseInt(n.substring(0, 2), 10),
        e = n.substring(2, 4),
        i = n.substring(4, 6),
        u = n.substring(6, 9),
        s = getCentury(u, i) + i;
      return isDNumber(n) && (r -= 40), new Date(s, e - 1, r);
    }
  }




  return {
    isValid: isValid(n),
    dob: getBirthdate(n),
    gender: getGender(n)
  }

};

export const dateOnly = (value) => {
  const date = '';
  if (date && String(value).match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)) {
    date = value;
  }
  return date;
};

const calDayBetween2Date = (date1, date2) => {
  const a = moment(moment(date1).format('YYYY-MM-DD'));
  const b = moment(moment(date2).format('YYYY-MM-DD'));
  return a.diff(b, 'days');
};

export const checkExpired = (time) => {
  const today = new Date();
  const expired = new Date(time);
  const days = calDayBetween2Date(expired, today);
  return days >= 0;
};

const replaceParams = (link, param) => {
  for (const key in param) {
    link = link.replace(key, param[key]);
  }
  return link;
};

export const replaceParam = (link, param, strParam = ':id') => {
  if (typeof param == 'object') {
    return replaceParams(link, param);
  }
  return link.replace(strParam, param);
};

export const handlePIRedirect = (paymentIntent, returnUrl = null) => {
  if (!returnUrl) {
    returnUrl = webConfig.webUrl + FinanceRoutes.Subscription.ReturnUrl;
  }

  // if status = succeeded.. redirect to thank you and with param paymentIntent ID
  if (paymentIntent.status == 'succeeded') {
    window.location.href = returnUrl + '?payment_intent=' + paymentIntent.id;
  }

  // redirect to 3D secure
  if (paymentIntent.next_action
    && paymentIntent.next_action.redirect_to_url
  ) {
    window.location.href = paymentIntent.next_action.redirect_to_url.url;
  }
};

export const validateBankNumber = (value) => {
  const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  const bankNumberWithoutSpacesAndPeriods = value.replace(/[\s.]+/g, '');
  if (bankNumberWithoutSpacesAndPeriods.length !== 11) {
    return false;
  } else {
    const checkDigit = parseInt(bankNumberWithoutSpacesAndPeriods.charAt(10), 10);
    const bankNumberWithoutCheckDigit = bankNumberWithoutSpacesAndPeriods.substring(0, 10);
    let sum = 0;
    for (let index = 0; index < 10; index++) {
      sum += parseInt(bankNumberWithoutCheckDigit.charAt(index), 10) * weights[index];
    }
    const remainder = sum % 11;
    return checkDigit === (remainder === 0 ? 0 : 11 - remainder);
  }
};

export const hasClass = (element, className) => {
  return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
};

export const regexDate = '^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$';
export const regexNaUy = '^([0-9]{4}|[0-9]{2})[./-]([0]?[1-9]|[1][0-2])[./-]([0]?[1-9]|[1|2][0-9]|[3][0|1])$';

export const mapDataExelToTable = (data) => {
  try {
    const columns = [];
    const rows = [];
    const objCheckLength = {};
    const regex = new RegExp('([0-9]+)|([a-zA-Z]+)', 'g');
    Object.keys(data).map((key) => {
      if (key === '!margins' || key === '!rows' || key === '!ref') {
        return true;
      }
      const [num, text] = key.match(regex);
      if (!objCheckLength[num]) {
        objCheckLength[num] = num;
        columns.push(num);
      }
      if (!objCheckLength[text]) {
        objCheckLength[text] = text;
        rows.push(text);
      }
      return true;
    });

    const result = [];

    rows.map((r) => {
      const dataInRow = columns.map((c) => {
        const value = data[`${c}${r}`];
        return value?.t === 'd' ? timeHelper.formatDate(value.v, 'DD.MM.YYYY') : value?.w ?? value?.v ?? '';
      });
      const isValueRow = dataInRow.some(
        (d) => (typeof d === 'string' && d.match(regexDate)) || (typeof d === 'string' && d.match(regexNaUy))
      );
      result.push({ data: dataInRow, isHeader: !isValueRow });
      return true;
    });

    const columnDetects = columns.map((val, index) => {
      const isAmount = result.some((r) => {
        let value = r.data[index];
        if (typeof value === 'number') {
          value = `${value}`;
        }
        if (value?.includes(',') || value?.includes('.') || Number.isFinite(+value)) {
          const newVal = currencyHelper.formatMoney(value);
          if (
            newVal !== value.toString().replace(/ /g, '') ||
            (newVal === value && typeof Number(newVal.replace(/,/g, '.') === 'number') && !isNaN(Number(newVal.replace(/,/g, '.'))))
          ) {
            if (value.includes(',') || value.includes('.')) {
              return true;
            }
            if (+newVal?.replace(/ /g, '').replace(/,/g, '.') < 0) {
              return true;
            }
          } else {
            return false;
          }
        }
        return false;
      });
      return { val, isAmount };
    });

    return { result, columns, columnDetects };
  } catch (error) {
    return { result: [], columns: [], columnDetects: [] };
  }
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};
