import { authorization, webConfig } from '../configs/system.configs';

import ServerConfigs from '../configs/server.configs';
import BaseServices from './base.services';

const config = ServerConfigs[process.env.NODE_ENV];

const getUserLogin = () => {
  if (localStorage.getItem('_user')) {
    return JSON.parse(localStorage.getItem('_user'));
  }
  return {};
};

export default {
  logout: async (callbackUrl) => {
    localStorage.removeItem('_user');
    localStorage.removeItem('asyncValues');
    localStorage.removeItem(authorization);
    window.location.href = webConfig.logoutUrl.replace(':redirectUrl', encodeURIComponent(callbackUrl || window.location.href));
  },
  getAuthenticateToken: () => {
    const _user = getUserLogin();
    return _user.AccessToken;
  },
  refreshToken: async refreshToken => {
    try {
      return await BaseServices.postData({ url: webConfig.refreshToken, data: { refreshToken } });
    } catch (error) {
      return { isSuccess: false, message: error.message };
    }
  },
  getUserLogin: () => getUserLogin()
};
