import 'react-phone-input-2/lib/material.css';

import React from 'react';
import DatePicker from 'react-datepicker';
import CurrencyHelper from '../../../helpers/currencyHelper';
import TimeHelper from '../../../helpers/timeHelper';
import InputPhoneNumber from '../input-phone-number/InputPhoneNumber';

export const renderAmountField = props => {
  const {
    id,
    label,
    input,
    meta,
    placeholder,
    onKeyDown
  } = props;
  const { touched, error } = meta;
  return (
    <div className="input-field">
      <label className="active" htmlFor={id}>{label}</label>
      <input
        id={id}
        className={touched && error ? 'invalid validate' : 'validate'}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        onChange={event => input.onChange(CurrencyHelper.handleChange(event.target.value))}
        onBlur={event => input.onBlur(CurrencyHelper.formatMoney(event.target.value))}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderField = props => {
  const {
    id,
    label,
    type,
    input,
    meta,
    placeholder,
    onKeyDown,
    className,
    classNameParent,
    disabled,
    classNameLabel,
    maxLength
  } = props;
  const { touched, error } = meta;
  return (
    <div className={`input-field ${classNameParent}`}>
      <label className={`active ${classNameLabel}`} htmlFor={id}>{label}</label>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        className={touched && error ? 'invalid validate ' : 'validate ' + className}
        // onBlur={evt => input.onBlur(evt.target.value.trim())}
        disabled={disabled}
        maxLength={maxLength}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderInputSearchField = props => {
  const {
    id,
    label,
    input,
    meta,
    placeholder,
    clickSearch,
    onKeyPress
  } = props;
  const { touched, error } = meta;
  return (
    <div className="input-field d-inline-table">
      <label className="active" htmlFor={id}>{label}</label>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        {...input}
        className={touched && error ? 'invalid validate' : 'validate'}
        onBlur={evt => input.onBlur(evt.target.value.trim())}
        onKeyPress={onKeyPress}
      />
      <span
        onClick={clickSearch}
        className="suffix"
      >
        <a className="btn waves-effect waves-light blue">
          <i className="large material-icons">search</i>
        </a>
      </span>
      {touched &&
        (error && <div className="d-table-row"><span className="red-text red-text" data-error={error}
          data-success="right">{error}</span></div>)}
    </div>
  );
};

export const renderSelectField = props => {
  const {
    input,
    className,
    label,
    id,
    multiple,
    options,
    meta: { touched, error },
    disabled
  } = props;
  return (
    <div className="input-field">
      <label className="active" htmlFor={id}>{label}</label>
      <select
        className={className}
        multiple={multiple}
        {...input}
        onChange={(event, value) => input.onChange(event, value)}
        disabled={disabled}
      >
        {options.map((x, index) => {
          const disabled = x.value === '' || x.disabled;
          return (
            <option disabled={disabled} key={index} value={x.value}>{x.label}</option>
          );
        })}
      </select>
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderHiddenInputField = props => {
  const {
    input,
    value,
    meta
  } = props;
  const { touched, error } = meta;
  return (
    <div>
      <input
        type="hidden"
        {...input}
        value={value}
      />
      {touched &&
        (error && <span className="red-text red-text" data-error={error} data-success="right">{error}</span>)}
    </div>);
};

export const renderInputDateField = props => {
  const {
    id,
    label,
    input,
    placeholder,
    onKeyDown,
    meta
  } = props;
  const { touched, error } = meta;
  return (
    <div className="input-field">
      <label className="active" htmlFor={id}>{label}</label>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        onChange={evt => input.onChange(TimeHelper.handleChange(evt.target.value))}
        onBlur={evt => input.onBlur(TimeHelper.onBlur(evt.target.value))}
        className={touched && error ? 'invalid validate' : 'validate'}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderCheckboxField = props => {
  const { input, label, description, onClick, classP, meta } = props;
  const { touched, error } = meta;
  return (
    <div className="input-field">
      {description && (
        <p className="check-box-description">
          {description}
        </p>
      )}
      <p className={classP}>
        <label>
          <input
            {...input}
            type="checkbox"
            className="filled-in"
            checked={input.value ? true : false}
            onChange={input.onChange}
            disabled={props.disabled}
            onClick={onClick}
          />
          <span>{label}</span>
        </label>
      </p>
      {touched && (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderRadioButtonField = props => {
  const {
    input,
    labelText,
    checked
  } = props;
  return (
    <p>
      <label>
        <input className="with-gap" {...input} type="radio" checked={checked} />
        <span>{labelText}</span>
      </label>
    </p>
  );
};

export const renderRadioGroupField = props => {
  const { input, meta, options, showRow, disabled } = props
  const hasError = meta.touched && meta.error;

  return (
    <>
      {options.map(o =>
        <div className={showRow ? "radio-elm float-left mr-3" : "radio-elm"} key={o.value}>
          <p>
            <label>
              <input type="radio" className="with-gap" {...input} value={o.value} checked={input.value === o.value} disabled={disabled} />
              <span className="pl-25">{o.title}</span>
            </label>
          </p>
        </div>
      )}
      {hasError && <div className="col s12 error">{meta.error}</div>}
    </>
  );
};

export const renderInputDateAdminVoucherField = props => {
  const {
    id,
    classes,
    label,
    input,
    placeholder,
    onKeyDown,
    meta
  } = props;
  const { touched, error } = meta;
  return (
    <div className="input-field">
      <label className="active" htmlFor={id}>{label}</label>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        onChange={evt => input.onChange(TimeHelper.handleChange(evt.target.value))}
        className={touched && error ? `invalid validate ${classes}` : `${classes} validate`}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderTextarea = props => {
  const {
    id,
    classes,
    label,
    input,
    placeholder,
    onKeyDown,
    meta,
    disabled,
    divClass
  } = props;
  const { touched, error } = meta;
  return (
    <div className={`input-field ${divClass}`}>
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        className={classes}
        {...input}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      {touched &&
        (error && <span className="red-text red-text" data-error={error} data-success="right">{error}</span>)}
    </div>);
};

export const renderAmountSubField = props => {
  const {
    id,
    label,
    input,
    classes,
    meta,
    placeholder,
    onKeyDown
  } = props;
  const { touched, error } = meta;
  let cls = `${classes} validate`;
  if (touched && error) cls = `${classes} invalid validate`;
  return (
    <div>
      <div className="input-field input-group mb-0">
        <label className="active" htmlFor={id}>{label}</label>
        <input
          autoComplete="off"
          id={id}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          {...input}
          className={cls}
          onBlur={evt => input.onBlur(evt.target.value.trim())}
        />
        <div className="input-group-append">
          <span className="input-group-text">.00</span>
        </div>
      </div>
      {touched &&
        (error && <div className="red-text error-text" data-error={error}>{error}</div>)}
    </div>
  );
};

export const renderDiscountField = props => {
  const {
    id,
    label,
    type,
    input,
    meta,
    placeholder,
    onKeyDown,
    className,
    disabled,
    renderIcon,
    classNameLabel,
    classNameParent
  } = props;
  const { touched, error } = meta;
  return (
    <div className={`input-field ${classNameParent}`}>
      <label className={`active ${classNameLabel}`} htmlFor={id}>{label}</label>
      <div className="custom-input-discount">
        <input
          autoComplete="off"
          id={id}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          {...input}
          className={touched && error ? 'invalid validate ' : 'validate ' + className}
          onChange={event => input.onChange(() => {
            const value = event.target.value.replace(/[^.,0-9]/g, '').replace(',', '.')
            const number = parseFloat(value)

            if (isNaN(number)) return '';

            if (isNaN(number) && value !== '')
              return parseFloat(0)
            if (number < 0) {
              return parseFloat(0)
            } else if (number >= 100) {
              return parseFloat(100)
            } else {
              const value = event.target.value.replace(/[^.,0-9]/g, '').replace('.', ',').replace(/,/g, ',');
              if (value.indexOf(',') > 0) {
                const newValue = value.split(',');
                const newNumber = newValue[0] ? parseFloat(newValue[0]) : 0;
                const result = newNumber + ',' + newValue[1];
                return result;
              } else {
                return parseFloat(value);
              }
            }
          })}
          disabled={disabled}
          max={100}
          maxLength="5"
          pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]"
        />
        {renderIcon && (
          <span className="custom-span-discount">%</span>
        )}
      </div>
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderInput = props => {
  const {
    id,
    input,
    meta,
    placeholder,
    onKeyDown,
    className,
    disabled,
    type
  } = props;
  const { touched, error } = meta;
  return (
    <>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        type={type}
        className={touched && error ? 'invalid validate ' : 'validate ' + className}
        disabled={disabled}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </>
  );
};

export const renderInputNumber = props => {
  const {
    id,
    input,
    meta,
    placeholder,
    onKeyDown,
    className,
    disabled,
    min,
    max,
    maxLength,
  } = props;
  const { touched, error } = meta;

  return (
    <>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        type="number"
        min={min}
        max={max}
        className={touched && error ? 'invalid validate ' : 'validate ' + className}
        disabled={disabled}
        maxLength={maxLength}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </>
  );
};

export const renderFrequencyNumber = props => {
  const {
    id,
    input,
    meta,
    placeholder,
    onKeyDown,
    onChangeAlias,
    className,
    disabled,
    maxLength
  } = props;
  const { touched, error } = meta;
  return (
    <>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        type="text"
        className={touched && error ? 'invalid validate ' : 'validate ' + className}
        disabled={disabled}
        onChange={event => input.onChange(() => {
          const value = event.target.value.replace(/[^\d]/g, '');
          const number = parseInt(value);
          if (isNaN(number)) return '';

          onChangeAlias && onChangeAlias(value);

          return parseInt(value);
        })}
        maxLength={maxLength}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </>
  );
};

export const renderDatePicker =
  ({
    input,
    type,
    className,
    selected,
    dateFormat,
    placeholder,
    onChange,
    onSelect,
    onChangeRaw,
    meta: { touched, error }
  }) => (
    <div>
      <div>
        <DatePicker
          {...input}
          onChange={onChange}
          onSelect={onSelect}
          onChangeRaw={onChangeRaw}
          selected={selected ? selected : new Date()}
          selectsStart
          placeholderText={placeholder}
          type={type}
          className={className}
          dateFormat={dateFormat}
        />
        {touched && error && <span className="red-text">{error}</span>}
      </div>
    </div>
  );

export const renderPhoneField = ({
  data,
  label,
  input,
  className,
  disabled,
  disabledCountryCode,
  defaultValue,
  setCountryCode,
  dataTarget,
  selectStyles,
  fieldClass,
  isBorder,
  isBorderRadius,
  meta: { touched, error }
}) => (
  <div className="input-field">
    <label className="active">{label}</label>
    <InputPhoneNumber
      input={input}
      data={data}
      className={className}
      disabled={disabled}
      disabledCountryCode={disabledCountryCode}
      defaultValue={defaultValue}
      setCountryCode={setCountryCode}
      dataTarget={dataTarget}
      selectStyles={selectStyles}
      fieldClass={fieldClass}
      isBorder={isBorder ?? true}
      isBorderRadius={isBorderRadius ?? true}
      meta
    />
    {touched && error && <span className="red-text">{error}</span>}
  </div>
);

export const renderPriceField = props => {
  const {
    id,
    label,
    type,
    input,
    meta,
    placeholder,
    onKeyDown,
    className,
    disabled,
    renderIcon,
    classNameLabel,
    classNameParent
  } = props;
  const { touched, error } = meta;
  return (
    <div className={`input-field ${classNameParent}`}>
      <label className={`active ${classNameLabel}`} htmlFor={id}>{label}</label>
      <div className="custom-input-discount">
        <input
          autoComplete="off"
          id={id}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          {...input}
          className={touched && error ? 'invalid validate ' : 'validate ' + className}
          onChange={event => input.onChange(() => {
            let value = event.target.value.replace(/[^-()\d/*+.,0-9]/g, '').replace('.', ',')

            let number = parseFloat(value);
            if (['-'].includes(value)) {
              number = value;
            }

            if (isNaN(number) && !['-'].includes(value)) return '';

            if (isNaN(number) && value !== '' && !['-'].includes(value))
              return parseFloat(0)

            value = value.replace(/,/g, ',');
            if (value.indexOf(',') > 0) {
              // allow only single number
              // const newValue = value.split(',');
              // const newNumber = newValue[0] ? parseFloat(newValue[0]) : 0;
              // const result = newNumber + ',' + newValue[1];
              return value;
            } else {
              if (value.charAt(0) === '0' && value.charAt(1) !== ',')
                return parseFloat(value);
              return value;
            }
          })}
          disabled={disabled}
          pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]"
        />
        {renderIcon && (
          <span className="custom-span-discount">%</span>
        )}
      </div>
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderQtyField = props => {
  const {
    id,
    label,
    type,
    input,
    meta,
    placeholder,
    onKeyDown,
    className,
    disabled,
    classNameLabel,
    classNameParent
  } = props;
  const { touched, error } = meta;
  return (
    <div className={`input-field ${classNameParent}`}>
      <label className={`active ${classNameLabel}`} htmlFor={id}>{label}</label>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        className={touched && error ? 'invalid validate ' : 'validate ' + className}
        onChange={event => input.onChange(() => {
          let value = event.target.value.replace(/[^.,0-9]/g, '').replace('.', ',')
          const number = parseFloat(value)

          if (isNaN(number)) return '';

          if (isNaN(number) && value !== '')
            return parseFloat(0)

          value = value.replace(/,/g, ',');
          if (value.indexOf(',') > 0) {
            const newValue = value.split(',');
            const newNumber = newValue[0] ? parseFloat(newValue[0]) : 0;
            const result = newNumber + ',' + newValue[1];
            return result;
          } else {
            if (value.charAt(0) === '0' && value.charAt(1) !== ',')
              return parseFloat(value);
            return value;
          }
        })}
        disabled={disabled}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};

export const renderInputString = props => {
  const {
    id,
    input,
    meta,
    label,
    placeholder,
    onKeyDown,
    className,
    disabled,
    maxLength
  } = props;
  const { touched, error } = meta;
  return (
    <div className={`input-field`}>
      <label className={`active`} htmlFor={id}>{label}</label>
      <input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...input}
        type="text"
        className={touched && error ? 'invalid validate ' : 'validate ' + className}
        disabled={disabled}
        onChange={event => input.onChange(() => {
          const value = event.target.value.replace(/[0-9]+/g, '');
          return value;
        })}
        maxLength={maxLength}
      />
      {touched &&
        (error && <span className="red-text" data-error={error} data-success="right">{error}</span>)}
    </div>
  );
};