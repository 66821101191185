export const CUSTOMER = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_CUSTOMER: 'SET_FILTER_CUSTOMER',
  RESET_FILTER_CUSTOMER: 'RESET_FILTER_CUSTOMER',

  FETCH_CUSTOMER_DATA_REQUEST: 'FETCH_CUSTOMER_DATA_REQUEST',
  FETCH_CUSTOMER_DATA_SUCCESS: 'FETCH_CUSTOMER_DATA_SUCCESS',
  FETCH_CUSTOMER_DATA_FAIL: 'FETCH_CUSTOMER_DATA_FAIL',

  FETCH_CUSTOMER_AUTOCOMPLETE_DATA_REQUEST: 'FETCH_CUSTOMER_AUTOCOMPLETE_DATA_REQUEST',
  FETCH_CUSTOMER_AUTOCOMPLETE_DATA_SUCCESS: 'FETCH_CUSTOMER_AUTOCOMPLETE_DATA_SUCCESS',
  FETCH_CUSTOMER_AUTOCOMPLETE_DATA_FAIL: 'FETCH_CUSTOMER_AUTOCOMPLETE_DATA_FAIL',

  ADD_CUSTOMER_REQUEST: 'ADD_CUSTOMER_REQUEST',
  ADD_CUSTOMER_REQUEST_SUCCESS: 'ADD_CUSTOMER_REQUEST_SUCCESS',
  ADD_CUSTOMER_REQUEST_FAIL: 'ADD_CUSTOMER_REQUEST_FAIL',

  UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_REQUEST_SUCCESS: 'UPDATE_CUSTOMER_REQUEST_SUCCESS',
  UPDATE_CUSTOMER_REQUEST_FAIL: 'UPDATE_CUSTOMER_REQUEST_FAIL',

  GET_CUSTOMER_DETAIL: 'GET_CUSTOMER_DETAIL',
  GET_CUSTOMER_DETAIL_SUCCESS: 'GET_CUSTOMER_DETAIL_SUCCESS',
  GET_CUSTOMER_DETAIL_FAIL: 'GET_CUSTOMER_DETAIL_FAIL',

  GET_DATA_BRREG: 'GET_DATA_BRREG',
  GET_DATA_BRREG_SUCCESS: 'GET_DATA_BRREG_SUCCESS',
  GET_DATA_BRREG_FAIL: 'GET_DATA_BRREG_FAIL',
};
